
import { defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiPencil, mdiDelete, mdiDownload, mdiInformationOutline } from '@mdi/js'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { useDeleteFile, useDownloadFile, useGetFiles } from '@/api/file'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { FileOutput } from '@/api/types/file'
import { ComplianceCheckOutput } from '@/api/types/complianceCheck'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'CommonFilesDialog',
  components: {
    AddEditFileDialog: () => import('@/components/common/AddEditFileDialog.vue'),
    CommonInfoDialog: () => import('@/components/common/CommonInfoDialog.vue'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entity: {
      type: Object as PropType<ComplianceCheckOutput>,
      default: null,
    },
    entityName: {
      type: String,
      default: null,
    },
    hasDownloadRight: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, { root }) => {
    const { addNotification } = useNotify()

    const { exec: getFiles, data: files } = useGetFiles()

    const isAddEditFileDialogOpen = ref(false)

    const isRowInfoDialogOpen = ref(false)

    const activeFile = ref()

    function onCloseAddEditInfoFileDialog(): void {
      isAddEditFileDialogOpen.value = false

      isRowInfoDialogOpen.value = false

      activeFile.value = null
    }

    function onClickInfo(file: FileOutput): void {
      activeFile.value = { ...file }

      isRowInfoDialogOpen.value = true
    }

    const { deleteFile } = useDeleteFile()

    async function onClickDelete(file: FileOutput): Promise<void> {
      try {
        await deleteFile(file.id)

        addNotification({
          text: root.$t('misc.files.delete.message') as string,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }

      init()
    }

    function onClickEdit(file: FileOutput): void {
      isAddEditFileDialogOpen.value = true

      activeFile.value = file
    }

    const { downloadFile } = useDownloadFile()

    function onClickDownload(file: FileOutput): void {
      downloadFile(file.id)
    }

    async function init(): Promise<void> {
      await getFiles({ params: { referenceIds: props.entity.id } })
    }
    init()

    const PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG: (keyof FileOutput)[] = ['updatedBy', 'updatedAt', 'fileHistory']

    return reactive({
      icons: {
        mdiPencil,
        mdiDelete,
        mdiDownload,
        mdiInformationOutline,
      },
      constants: {
        PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,

        Rights,
      },
      state: {
        isAddEditFileDialogOpen,
        isRowInfoDialogOpen,

        files,
        activeFile,
      },
      listeners: {
        onCloseAddEditInfoFileDialog,
        onClickDownload,
        onClickDelete,
        onClickEdit,
        onClickInfo,
      },
      functions: {
        dateDashNotationToDot,
        hasSufficientRights,
        init,
      },
    })
  },
})
