var render = function () {
  var _vm$state$activeFile;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "1000"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('misc.files.title')) + " "), _c('v-spacer'), _vm.functions.hasSufficientRights(_vm.constants.Rights.FILE_CREATE) ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.state.isAddEditFileDialogOpen = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('misc.files.form.add.title')) + " ")]) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "my-5 content__height"
  }, [_vm.state.files.length ? _c('v-simple-table', [_c('thead', [_c('tr', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.files.table.col.title.title")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("misc.files.table.col.title.updatedAt")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  })])]), _c('tbody', _vm._l(_vm.state.files, function (file) {
    return _c('tr', {
      key: file.id,
      staticClass: "table-row-color"
    }, [_c('td', [_vm._v(" " + _vm._s(file.title) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(file.updatedAt)) + " ")]), _c('td', {
      staticClass: "text-right"
    }, [_vm.hasDownloadRight ? _c('v-btn', {
      staticClass: "mr-2",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickDownload(file);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDownload) + " ")])], 1) : _vm._e(), _c('v-btn', {
      staticClass: "mr-2",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickInfo(file);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiInformationOutline) + " ")])], 1), _vm.functions.hasSufficientRights(_vm.constants.Rights.FILE_CREATE) ? _c('v-btn', {
      staticClass: "mr-2",
      attrs: {
        "icon": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickEdit(file);
        }
      }
    }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e(), _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
              attrs = _ref.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "x-small": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)];
        }
      }], null, true)
    }, [_c('v-card', {
      attrs: {
        "width": "400px"
      }
    }, [_c('v-card-title', {
      staticClass: "pb-0 text-subtitle-2 break-word"
    }, [_vm._v(" " + _vm._s(_vm.$t('misc.files.delete.text')) + " ")]), _c('v-card-actions', [_c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.listeners.onClickDelete(file);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.yes')) + " ")]), _c('v-spacer'), _c('v-btn', {
      staticClass: "rounded-lg",
      attrs: {
        "small": "",
        "outlined": "",
        "elevation": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.no')) + " ")])], 1)], 1)], 1)], 1)]);
  }), 0)]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('misc.files.noFiles')) + " ")])], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")])], 1)], 1), _vm.state.isAddEditFileDialogOpen ? _c('AddEditFileDialog', {
    attrs: {
      "entity-name": _vm.entityName,
      "entity-id": _vm.entity.id,
      "file-to-edit-id": (_vm$state$activeFile = _vm.state.activeFile) === null || _vm$state$activeFile === void 0 ? void 0 : _vm$state$activeFile.id
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.init();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditInfoFileDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditFileDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditFileDialogOpen", $$v);
      },
      expression: "state.isAddEditFileDialogOpen"
    }
  }) : _vm._e(), _vm.state.isRowInfoDialogOpen ? _c('CommonInfoDialog', {
    attrs: {
      "entity": _vm.state.activeFile,
      "properties-to-show": _vm.constants.PROPERTIES_TO_SHOW_IN_ROW_INFO_DIALOG,
      "custom-parent-key": "files",
      "translation-key": 'misc.files'
    },
    on: {
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditInfoFileDialog();
      }
    },
    model: {
      value: _vm.state.isRowInfoDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isRowInfoDialogOpen", $$v);
      },
      expression: "state.isRowInfoDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }