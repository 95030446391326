import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  FileId,
  FileInput,
  FileOutput,
  UseDeleteFile,
  UseDownloadFile,
  UseGetFile,
  UseGetFiles,
  UseUpdateFile,
  UseUploadFile,
} from './types/file'
import { downloadHandler } from './utils/downloadHandler'

const VERSION = 'v1'
const RESOURCE = 'files'

function useGetFiles(): UseGetFiles {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetFiles['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })
  return axios
}

function useGetFile(): UseGetFile {
  const axios = useAxios<PromiseType<ReturnType<UseGetFile['getFile']>>>({ method: 'GET' })

  function getFile(id: FileId): Promise<FileOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getFile,
  }
}

function useDownloadFile(): UseDownloadFile {
  const axios = useAxios<Blob>({ method: 'GET' })

  const { getFile } = useGetFile()

  async function downloadFile(id: FileId): Promise<void> {
    const axiosResponse = await axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}/file`, responseType: 'blob' })
    const fileMetadata = await getFile(id)

    downloadHandler(axiosResponse, fileMetadata.name)
  }

  return {
    ...axios,
    downloadFile,
  }
}

function useUploadFile(): UseUploadFile {
  function uploadFile(data: FileInput): Promise<FileOutput> {
    const formData = new FormData()
    Object.entries(data).map(([key, value]) => {
      if (key === 'file') {
        formData.append(key, value as File)
      } else {
        formData.append(key, String(value))
      }
    })

    return fetch(`/${VERSION}/${RESOURCE}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => response.json())
  }

  return {
    uploadFile,
  }
}

function useUpdateFile(): UseUpdateFile {
  function updateFile(id: FileId, data: FileInput): Promise<FileOutput> {
    const formData = new FormData()
    Object.entries(data).map(([key, value]) => {
      if (key === 'file') {
        formData.append(key, value as File)
      } else {
        formData.append(key, String(value))
      }
    })

    return fetch(`/${VERSION}/${RESOURCE}/${id}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => response.json())
  }

  return {
    updateFile,
  }
}

function useDeleteFile(): UseDeleteFile {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteFile['deleteFile']>>>({ method: 'DELETE' })

  function deleteFile(id: FileId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteFile,
  }
}

export { useGetFiles, useGetFile, useDownloadFile, useUploadFile, useUpdateFile, useDeleteFile }
